<template>
  <div class="community oepnX">
    <!--    <div class="communityBg">-->
    <!--      <div class="communityBg2"></div>-->
    <!--    </div>-->
    <van-tabs
      class="tabs oepnX"
      swipeable
      background="rgba(0,0,0,0)"
      @change="tabChange"
      line-width="22"
      line-height="3"
      title-inactive-color="#333333"
      title-active-color="#333333"
      v-model="tabActive"
    >
      <van-tab title="关注" class="tabContent">
        <template #title>
          <img
            class="titleActiveIcon"
            v-if="tabActive == 0"
            src="@/assets/png/communityFollowActiveIcon.png"
            alt=""
          />
          <img
            class="titleIcon"
            v-else
            src="@/assets/png/communityFollowIcon.png"
            alt=""
          />
        </template>
        <div
          class="followContent overflow-y-auto"
          :id="`${prefixKey}${tabActive}`"
        >
          <div class="fiveGrid" v-if="bannerList && bannerList.length">
            <div
              class="rectAdvBox"
              @click="goAdv(item)"
              v-for="item in bannerList.slice(0, 10)"
              :key="item.id"
            >
              <ImgDecypt class="rectAdvIcon" :src="item.cover" />
              <div class="rectName">{{ item.title }}</div>
            </div>
          </div>
          <FollowTab v-if="tabActive == 0" />
        </div>
        <!--    <PullRefresh v-if="!gIsLoading" :loading="gLoading" :refreshing="gRefreshing" :finished="gFinished"-->
        <!--                 @onLoad="onLoad" @onRefresh="onRefresh" :isNoData="gIsNoData" :error="error"-->
        <!--                 :is-higeht-max="true"-->
        <!--                 :hasAdvPagination="true" className="VideoDetailsPage">-->
        <!--     <div class="focusContent">-->

        <!--      &lt;!&ndash; 官方博主 &ndash;&gt;-->
        <!--      <div class="authorityTopics" @touchmove.stop v-if="authorityBloggerList && authorityBloggerList.length > 0">-->
        <!--       <div class="topicsHead">-->
        <!--        <div class="topicsTitle">-->
        <!--         <span>官方博主</span>-->
        <!--        </div>-->
        <!--        <div class="more" @click="moreAuthorityBlogger">-->
        <!--         &lt;!&ndash;                          <span>更多</span>&ndash;&gt;-->
        <!--         <img src="@/assets/png/rightIcon.png">-->
        <!--        </div>-->
        <!--       </div>-->
        <!--       <div class="topicsContent">-->

        <!--        <div class="topicsItem" v-for="(item,index) in authorityBloggerList" :key="index"-->
        <!--             @click.stop="jumpUserHome(item)">-->
        <!--         <div class="portrait">-->
        <!--          <ImgDecypt class="avatarImg" :src="item.portrait" round></ImgDecypt>-->
        <!--          <svg-icon v-if="item.superUser > 0" icon-class="authIcon" class="authIcon"></svg-icon>-->
        <!--         </div>-->
        <!--         <div class="topicsInfo">-->
        <!--          <div class="topicsInfoTitle">-->
        <!--           <span>{{ item.name }}</span>-->
        <!--          </div>-->
        <!--          <div class="topicsInfoContent">-->
        <!--           <span>{{ item.desc }}</span>-->
        <!--          </div>-->
        <!--         </div>-->
        <!--        </div>-->
        <!--       </div>-->
        <!--      </div>-->

        <!--      <div class="communityList">-->
        <!--       <CommunityList :communityList="gCommunityList"></CommunityList>-->
        <!--      </div>-->
        <!--     </div>-->
        <!--    </PullRefresh>-->
        <!--    <Loading v-else/>-->
      </van-tab>
      <van-tab title="推荐" class="tabContent">
        <template #title>
          <img
            class="titleActiveIcon"
            v-if="tabActive == 1"
            src="@/assets/png/communityRecommendActiveIcon.png"
            alt=""
          />
          <img
            class="titleIcon"
            v-else
            src="@/assets/png/communityRecommendIcon.png"
            alt=""
          />
        </template>
        <div
          class="makeContent overflow-y-auto"
          :id="`${prefixKey}${tabActive}`"
          @scroll="handleScroll"
        >
          <PullRefresh
            v-if="!rIsLoading"
            :loading="rLoading"
            :refreshing="rRefreshing"
            :finished="rFinished"
            @onLoad="onLoad"
            @onRefresh="onRefresh"
            :isNoData="rIsNoData"
            :error="error"
            :hasAdvPagination="true"
          >
            <!-- 轮播广告 -->
            <!--      <div class="advertising">-->
            <!--       <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">-->
            <!--        <van-swipe-item v-for="(item,index) in bannerList" :key="index" @click="goAdv(item)">-->
            <!--         <ImgDecypt :src="item.cover"/>-->
            <!--        </van-swipe-item>-->
            <!--       </van-swipe>-->
            <!--      </div>-->
            <div class="fiveGrid" v-if="bannerList && bannerList.length">
              <div
                class="rectAdvBox"
                @click="goAdv(item)"
                v-for="item in bannerList.slice(0, 10)"
                :key="item.id"
              >
                <ImgDecypt class="rectAdvIcon" :src="item.cover" />
                <div class="rectName">{{ item.title }}</div>
              </div>
            </div>

            <!-- 热门话题 -->
            <div
              class="hotTopics"
              @touchmove.stop
              v-if="hotspotWonderList && hotspotWonderList.length > 0"
            >
              <div class="topicsHead">
                <div class="topicsTitle">
                  <span>热门话题</span>
                </div>
                <div class="more" @click="moreTopic">
                  <span>查看更多</span>
                  <img src="@/assets/png/rightIcon.png" />
                </div>
              </div>
              <div class="topicsContent">
                <div
                  class="topicsItem"
                  v-for="(item, index) in hotspotWonderList.slice(0, 4)"
                  :key="index"
                  @click="tagJump(item)"
                >
                  <div class="portrait">
                    <ImgDecypt class="avatarImg" :src="item.coverImg" />
                  </div>
                  <div class="topicsInfo">
                    <div class="topicsInfoTitle">
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="topicsInfoContent">
                      <span>{{ item.collCount }}人参与</span>
                    </div>
                    <!--                    <div class="topicsInfoContent">-->
                    <!--                      <span>{{ item.tagDesc }}</span>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>

            <!-- 帖子列表 -->
            <div class="communityList">
              <CommunityList :communityList="rCommunityList"></CommunityList>
            </div>
          </PullRefresh>
          <Loading v-else />
        </div>
      </van-tab>
      <van-tab title="写真" class="tabContent">
        <template #title>
          <img
            class="titleActiveIcon"
            v-if="tabActive == 2"
            src="@/assets/png/communityPhotoActiveIcon.png"
            alt=""
          />
          <img
            class="titleIcon"
            v-else
            src="@/assets/png/communityPhotoIcon.png"
            alt=""
          />
        </template>
        <PhotoList :modulesList="modulesList" />
      </van-tab>
      <van-tab title="应用" class="tabContent">
        <template #title>
          <img
            class="titleActiveIcon"
            v-if="tabActive == 3"
            src="@/assets/png/communityAppActiveIcon.png"
            alt=""
          />
          <img
            class="titleIcon"
            v-else
            src="@/assets/png/communityAppIcon.png"
            alt=""
          />
        </template>
        <AppList />
      </van-tab>
    </van-tabs>

    <div class="prizeDraw" @click.stop="openLottery">
      <img src="@/assets/png/prizeDraw.png" />
    </div>

    <div
      class="publishBtn"
      @click.stop="openRelease"
      v-show="tabActive == 1 && showPublish"
    >
      <img src="@/assets/png/publishIcon.png" />
    </div>

    <van-overlay
      :show="releaseShow"
      @click="releaseShow = false"
      z-index="2001"
    >
      <div class="releaseBtns" @click.stop>
        <div class="releaseBtnGroup">
          <div class="item" @click.stop="jumpVideo">
            <img src="@/assets/png/videoIcon.png" />
            <!--      <span>视频</span>-->
          </div>
          <div class="item" @click.stop="jumpImg">
            <img src="@/assets/png/imgIcon.png" />
            <!--      <span>图片</span>-->
          </div>
          <!--     <div class="division"></div>-->
        </div>
      </div>
    </van-overlay>

    <!--      <div class="collectBtn" v-else @click="cancelCollectionWorks">-->
    <!--          <img src="@/assets/png/hasCollectBtn.png">-->
    <!--      </div>-->
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import AppList from "./appList/index.vue";
import PhotoList from "./photoList/index.vue";
import {
  queryCommunityList,
  queryFollowDynamicsList,
  queryHotspotWonderList,
  queryRecommendUserList,
} from "@/api/community";

import { careOrcancle, zan, cancleZan } from "@/api/user";
import ImgDecypt from "@/components/ImgDecypt";
import { setSessionItem } from "@/utils/longStorage";
import CommunityList from "@/components/community/CommunityList";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";
import { mapGetters } from "vuex";
import FollowTab from "@/views/community/followTab/index.vue";
import store from "@/store";
import TabPageScroll from "@/minxs/resetTabPageScrollTop";

export default {
  name: "Community",
  components: {
    FollowTab,
    PullRefresh,
    Loading,
    ImgDecypt,
    CommunityList,
    AppList,
    PhotoList,
  },
  mixins: [TabPageScroll],
  data() {
    return {
      gIsLoading: true,
      rIsLoading: true,
      gLoading: true,
      rLoading: true,
      gFinished: false,
      rFinished: false,
      gIsNoData: false,
      rIsNoData: false,
      error: false,
      gPageNumber: 1,
      rPageNumber: 1,
      gPageSize: 12,
      rPageSize: 12,
      rCommunityList: [],
      tabActive: 1,
      commentsShow: false,
      gRefreshing: false,
      rRefreshing: false,
      hotspotWonderList: [],
      authorityBloggerList: [],
      releaseShow: false,
      bannerList: [],
      modulesList: [],
      showPublish: true,
      timeout: null,
      prefixKey: "community_page_",
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
      isVip: "isVip",
    }),
  },
  created() {
    if (this.$route.query.t) {
      this.tabActive = Number(this.$route.query.t);
      this.tabChange(this.tabActive);
    }
  },
  mounted() {
    let obj = {
      id: "123",
      moduleName: "我的收藏",
    };
    this.modulesList =
      [obj].concat(this.$store.getters.modulesInfo?.photo) || [];
    console.log(this.modulesList, "写真");
    this.getCommunityList("refresh");
    this.getHotspotWonderList();
    this.bannerList = getAdItem(AdType.COMMUNITYADV);
    setTimeout(() => {
      this.tabChange(this.tabActive);
    }, 1000);
  },
  activated() {
    this.$store.commit(
      "app/SET_ACTIVE_INDEX",
      `${this.prefixKey}${this.tabActive}`
    );
  },
  methods: {
    handleScroll(e) {
      this.showPublish = false;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.showPublish = true;
      }, 300);
    },

    // 跳转用户主页
    jumpUserHome(item) {
      this.$router.push({
        path: "/userHomePage",
        query: {
          uid: item.uid,
        },
      });
    },
    onRefresh() {
      this.rRefreshing = true;
      this.rPageNumber = 1;
      this.rFinished = false;
      this.rIsNoData = false;
      this.getCommunityList("refresh");
    },
    onLoad() {
      if (this.tabActive == 1) {
        this.rLoading = true;
        if (this.error) {
          this.error = false;
        } else {
          this.rPageNumber++;
        }
        this.getCommunityList();
      } else {
        if (this.error) {
          this.error = false;
        } else {
          this.gPageNumber++;
        }
      }
    },
    async getCommunityList(type) {
      /**
       * 帖子发现列表
       */
      let req = {
        pageNumber: this.rPageNumber,
        pageSize: this.rPageSize,
        tag: "最新",
        reqDate: new Date().toISOString(),
      };
      let res = await this.$Api(queryCommunityList, req);

      this.rLoading = false;
      this.rRefreshing = false;
      this.rIsLoading = false;
      try {
        if (res && res.code == 200) {
          console.log(res.data.list);

          if (res.data.list) {
            if (type == "refresh") {
              this.rCommunityList = res.data.list;
            } else {
              this.rCommunityList = this.rCommunityList.concat(res.data.list);
            }
          } else {
            this.rCommunityList = [];
          }

          // if (this.rPageNumber === 1 && this.rCommunityList.length === 0) {
          //   this.rIsNoData = true;
          // }

          if (!res.data.hasNext || res.data.list.length < req.rPageSize) {
            this.rFinished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.rLoading = false;
      }
    },

    async tabChange(index) {
      // this.rIsLoading = true;
      // await this.getCommunityList('refresh');
      this.$store.commit(
        "app/SET_ACTIVE_INDEX",
        `${this.prefixKey}${this.tabActive}`
      );
      this.onChange(`${this.prefixKey}${this.tabActive}`);
      // store.commit("app/SET_SCROLL_INFO", {
      //   key: this.$route.path,
      //   index: index,
      // });
    },
    async getHotspotWonderList() {
      /**
       * 获取热门话题列表
       */
      let req = {
        pageNumber: 1,
        pageSize: 10,
      };
      let res = await this.$Api(queryHotspotWonderList, req);

      if (res && res.code == 200) {
        this.hotspotWonderList = res.data.list;
      }
    },
    async getAuthorityBloggerList() {
      /**
       * 获取官方博主列表
       */
      let req = {
        pageNumber: 1,
        pageSize: 50,
      };
      let res = await this.$Api(queryRecommendUserList, req);
      if (res && res.code == 200) {
        this.authorityBloggerList = res.data.list;
      }
    },
    moreTopic() {
      /**
       * 更多话题（热门话题）
       */
      this.$router.push({
        path: "/hotTopic",
      });
    },

    moreAuthorityBlogger() {
      /**
       * 更多官方博主
       */
      this.$router.push({
        path: "/authorityBlogger",
      });
    },
    releasePage() {
      /**
       * 发布作品
       */

      this.releaseShow = false;

      this.$router.push({
        path: "/releasePage",
      });
    },
    goAdv(item) {
      jumpAdv(item);
    },
    tagJump(item) {
      /**
       * 话题详情
       */
      this.$router.push({
        path: "/topicDetails",
        query: {
          id: item.id,
        },
      });
    },
    openLottery() {
      this.$router.push({
        path: "/lottery",
      });
    },

    openRelease() {
      // console.log(123213)
      // if (this.userInfo.isVip) {
      this.releaseShow = !this.releaseShow;
      // } else {
      //     this.$bus.$emit("vipPopup", {
      //         // id: "",
      //         state: 1,
      //         // goldenNum: this.postInfo.coins,
      //         closeBtn: () => {
      //             this.$bus.$emit("closeVipPopup");
      //         }
      //     });
      // }
    },
    jumpImg() {
      this.$router.push("/publishImg");
      // if (this.userInfo.isVip) {
      //     this.$router.push("/publishImg")
      // } else {
      //     this.$bus.$emit("vipPopup", {
      //         // id: "",
      //         state: 1,
      //         // goldenNum: this.postInfo.coins,
      //         closeBtn: () => {
      //             this.$bus.$emit("closeVipPopup");
      //         }
      //     });
      // }
    },
    jumpVideo() {
      this.$router.push("/publishVideo");
      // if (this.userInfo.isVip) {
      //     this.$router.push("/publishVideo")
      // } else {
      //     this.$bus.$emit("vipPopup", {
      //         // id: "",
      //         state: 1,
      //         // goldenNum: this.postInfo.coins,
      //         closeBtn: () => {
      //             this.$bus.$emit("closeVipPopup");
      //         }
      //     });
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
.community {
  background: url("../../assets/png/communityHeaderBg.png") no-repeat;
  background-size: 100% 315px;
  background-color: #f9f9f9;
  .publishBtn {
    position: fixed;
    bottom: 80px;
    right: 10px;
    z-index: 2222;
    img {
      width: 54px;
      height: 54px;
    }
  }

  .communityBg {
    width: 100%;
    height: 345px;
    position: absolute;
    background: linear-gradient(
      180deg,
      #a2de8a 0%,
      rgba(162, 222, 138, 0) 100%
    );

    .communityBg2 {
      position: relative;
      width: 613.333px;
      height: 345px;
      background: url("../../assets/png/communityBg.png"),
        lightgray 50% / cover no-repeat;
      background-size: 100% 100%;
      filter: blur(2.5px);
      //mix-blend-mode: color-burn;
      mix-blend-mode: overlay;
    }
  }

  /deep/ .van-tabs__nav--line {
    width: 130px;
    padding-left: 5px;
  }

  /deep/ .van-tabs__wrap {
  }

  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 51px;
  }

  /deep/ .van-tab--active {
    margin-bottom: 2px;
  }

  /deep/ .van-tab {
    font-size: 14px;
  }

  /deep/ .van-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  /deep/ .van-tabs__line {
    display: none;
    //bottom: 25px;
    //background: transparent;
    //background: url("../../assets/png/tabLine.png");
    //background-repeat: no-repeat;
    //background-size: 100% 100%;
  }

  .releaseBtns {
    display: flex;
    justify-content: flex-end;
    margin-top: 51px;
    //margin-right: 12px;
    position: fixed;
    bottom: 142px;
    right: 16px;

    .releaseBtnGroup {
      //width: 121px;
      //height: 41px;
      //background: linear-gradient(180deg, #E6FEFF 0%, #FFF 100%);
      //background: #fff;
      //border-top-left-radius: 6px;
      //border-bottom-left-radius: 6px;
      //border-bottom-right-radius: 6px;
      //padding: 10px 8px;

      display: flex;
      flex-direction: column;
      align-items: center;
      //justify-content: center;
      font-size: 15px;
      color: rgb(255, 255, 255);

      .item {
        display: flex;
        align-items: center;
        color: rgba(176, 159, 217, 1);
        font-weight: bold;
        font-size: 14px;

        img {
          width: 40px;
          height: 40px;
          margin-top: 7px;
          //margin-right: 10px;
        }
      }

      //.division {
      // width: 84px;
      // height: 1px;
      // background: rgba(176, 159, 217, 1);
      // margin: 6px 0;
      //}
    }
  }

  .advertising {
    padding: 0 12px;
    margin-top: 12px;

    .swipe {
      height: 146.25px;

      .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: rgb(43, 39, 72);
        border-radius: 8px;
      }

      /deep/ .van-swipe__indicators {
        left: auto;
        right: 0;
        align-items: center;
      }

      /deep/ .van-image__img {
        border-radius: 8px;
      }

      /deep/ .van-swipe__indicator {
        width: 6px;
        height: 6px;
        background: rgba(148, 214, 218, 0.45) !important;
      }

      /deep/ .van-swipe__indicator--active {
        width: 9px;
        height: 9px;
        background: #94d6da !important;
      }
    }
  }
  .fiveGrid {
    padding: 0 10px;
    margin-top: 18px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 12px;
    .rectAdvBox {
      width: 60px;
      .rectAdvIcon {
        height: 60px;
        width: 60px;
        border-radius: 8px;
        overflow: hidden;
      }
      .rectName {
        font-size: 12px;
        color: #333;
        margin-top: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
      }
    }
  }

  .authorityTopics {
    //border-bottom: solid #73CD50 1px;
    padding: 18px 16px 18px 16px;

    .topicsHead {
      color: #212121;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      align-items: center;

      .topicsTitle {
        font-weight: bold;
      }

      .more {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: rgba(119, 119, 119, 1);
      }

      img {
        width: 12px;
        height: 12px;
        //margin-left: 6.1px;
      }
    }

    .topicsContent {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      margin-top: 12px;
      border-radius: 8px;
      //padding: 0 8px 14px 8px;
      background-size: 100% 100%;
      overscroll-behavior: contain;

      .topicsItem {
        //margin-top: 12px;
        margin-right: 18px;
        //width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: space-between;

        .portrait {
          width: 60px;
          height: 60px;
          position: relative;

          .authIcon {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 15px;
            height: 15px;
          }
        }

        .topicsInfo {
          margin-left: 6px;
          font-size: 12px;
          //width: 90px;

          .topicsInfoTitle {
            color: #333;
            font-size: 10px;
            //text-align: center;
            font-weight: 500;
            //font-weight: bold;
            margin-top: 6px;

            width: 60px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; // 默认不换行；
          }

          .topicsInfoContent {
            width: 60px;
            color: #666;
            font-size: 10px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; // 默认不换行；
            margin-top: 2px;
          }
        }
      }
    }
  }

  .hotTopics {
    //border-bottom: solid #73CD50 1px;
    margin: 20px 10px 30px;
    padding: 16px 14px 0;
    height: 186px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);

    .topicsHead {
      margin-top: 4px;
      color: #212121;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      align-items: center;

      .topicsTitle {
        font-weight: bold;
      }

      .more {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: rgba(119, 119, 119, 1);
      }

      img {
        width: 12px;
        height: 12px;
        //margin-left: 6.1px;
      }
    }

    .topicsContent {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      //flex-wrap: nowrap;
      //overflow-x: auto;
      margin-top: 12px;
      border-radius: 8px;
      //padding: 0 8px 14px 8px;
      background-size: 100% 100%;
      overscroll-behavior: contain;

      .topicsItem {
        margin-bottom: 16px;
        margin-right: 33px;
        //width: 50%;
        display: flex;
        //flex-direction: column;
        align-items: center;
        //justify-content: space-between;

        .portrait {
          width: 53px;
          height: 53px;
          border-radius: 4px;
          //background: rgb(230, 230, 230);

          /deep/ .van-image__img {
            border-radius: 4px;
          }
        }

        .topicsInfo {
          margin-left: 10px;
          font-size: 12px;
          //width: 90px;

          .topicsInfoTitle {
            color: #333333;
            font-size: 16px;
            font-weight: 600;
            //text-align: center;
            //font-weight: bold;
            margin-top: 4px;
            white-space: nowrap;
          }

          .topicsInfoContent {
            color: #999999;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; // 默认不换行；
            margin-top: 4px;
          }
        }
      }
    }
  }

  .tabs {
    /deep/ .van-tabs__wrap {
      //  background: url("~@/assets/png/videoModuleBg.png") no-repeat;
      //  backdrop-filter: blur(50px);
      //  background-size: 100%;
    }
    /deep/ .van-tab {
      margin: 0 4px;
    }
    .titleActiveIcon {
      width: 54px;
      height: 26px;
    }
    .titleIcon {
      width: 36px;
      height: 20px;
    }
    //   display: flex;

    .tabContent {
      height: calc(100vh - 51px - 86px);

      .makeContent {
        //padding: 0 12px;
        height: calc(100vh - 51px - 86px);
        // background: rgba(245, 245, 245, 1);
      }
      .followContent {
        height: calc(100vh - 51px - 86px);
      }
    }
  }

  .prizeDraw {
    position: absolute;
    top: 5px;
    right: 5px;
    //right: 12px;
    //padding-top: 12px;
    //background: rgb(27,22,76);

    img {
      width: 49px;
      height: 41px;
    }
  }

  .communityList {
    //margin-top: 30px;
    background: rgba(245, 245, 245, 1);
  }
}
</style>
