<template>
  <div class="caricatureTab">
    <van-tabs
      class="navTabs oepnX"
      line-width="0"
      line-height="0"
      v-model="activeIndex"
      background="rgba(0,0,0,0)"
      v-if="modulesList && modulesList.length"
      @change="tabChange"
      :lazy-render="true"
      swipeable
    >
      <van-tab v-for="(item, index) in modulesList" :key="index">
        <template #title>
          <span
            class="navTabTitle"
            :class="{ navTabTitleActive: activeIndex == index }"
          >
            {{ item.moduleName }}
          </span>
        </template>
        <CaricatureItem
          :item="item"
          :index="index"
          :scrollId="`${prefixKey}${index}`"
          :noticeKey="noticeKey"
        />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import TabPageScroll from "@/minxs/resetTabPageScrollTop";
import CaricatureItem from "./caricatureItem.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    CaricatureItem,
  },
  mixins: [TabPageScroll],
  computed: {
    ...mapGetters({
      caricatureTabIndex: "caricatureTabIndex",
    }),
  },
  data() {
    return {
      activeIndex: 0,
      modulesList: [],
      prefixKey: "caricature_page_",
      noticeKey: 1,
    };
  },
  activated() {
    this.$store.commit(
      "app/SET_ACTIVE_INDEX",
      `${this.prefixKey}${this.activeIndex}`
    );
    this.activeIndex = this.caricatureTabIndex;
  },
  mounted() {
    this.modulesList = this.$store.getters.modulesInfo?.caricature || [];
    setTimeout(() => {
      this.tabChange(0);
    }, 1000);
  },
  methods: {
    tabChange(index) {
      this.$store.commit("app/SET_CARICATUREINDEX", index);
      this.noticeKey += 1;
      this.$store.commit('app/SET_ACTIVE_INDEX', `${this.prefixKey}${this.activeIndex}`);
      this.onChange(`${this.prefixKey}${this.activeIndex}`);
    },
  },
};
</script>

<style scoped lang="scss">
.caricatureTab {
  height: 100%;
  // background: #f5f5f5;

  .navTabTitle {
    font-size: 14px;
    color: #f5f5f5;
    font-weight: 600;
  }

  .navTabTitleActive {
    color: #ffe7bd;
    font-size: 18px;
    font-weight: 600;
  }

  /deep/ .van-tabs__wrap {
    //background: #000;
    //background-size: 100% 100%;
    // background: url("~@/assets/png/videoModuleBg.png") no-repeat bottom;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: -2px !important;
  }

  /deep/ .van-tabs__nav {
    margin-left: -10px;
  }

  /deep/ .van-tab {
    //padding: 0;
    padding-right: 16px;
  }

  /deep/ .van-tabs__line {
    background: transparent;
    background-image: url("~@/assets/png/tabLine2.png") !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 14px !important;
    height: 7px !important;
    bottom: 20px;
  }
  /deep/ .van-tabs__nav--complete {
    padding-left: 0;
  }
}
</style>
