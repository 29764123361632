<template>
  <div class="photoList">
   <div class="photoContent">
    <div class="leftBox">
     <van-sidebar v-model="tabActive" @change="onChange">
      <van-sidebar-item :title="item.moduleName" v-for="(item, index) in modulesList" :key="index" />
     </van-sidebar>
<!--     <div-->
<!--       class="item"-->
<!--       :class="{title: index === item.id }"-->
<!--       v-for="(item, index) in modulesList"-->
<!--       :key="index"-->
<!--       @click="select(item, index)"-->
<!--     >-->
<!--      {{ item.moduleName }}-->
<!--     </div>-->
    </div>
    <div class="rightBox overflow-y-auto">
     <PullRefresh
       v-if="!isLoading"
       :loading="loading"
       :refreshing="refreshing"
       :finished="finished"
       @onLoad="onLoad"
       @onRefresh="onRefresh"
       :isNoData="isNoData"
       :error="error"
       :hasAdvPagination="true"
     >
      <div class="waterfall">
       <div
         class="photoBox"
         v-for="item in videoList"
         :key="item.id"
         :data-a="Math.floor(1 / item.ratio)"
         :style="computedRatio(item)"
         @click="openDetail(item)"
       >
        <template v-if="item.cover">
         <ImgDecypt
           class="cover"
           :src="item.cover"
           :key="item.cover"
           :style="{ height: computedCoverHeight(item) + 'px' }"
         >
          <div class="label" v-if="item.freeArea || item.originCoins === 0">
           <img class="icon" src="@/assets/png/freeLabelIcon.png" alt="">
          </div>
<!--          <div class="label" v-else-if="item.originCoins > 0">-->
<!--           <img class="icon" src="@/assets/png/goldLabelIcon.png" alt="">-->
<!--          </div>-->
<!--          <div class="label" v-else-if="item.originCoins === 0">-->
<!--           <img class="icon" src="@/assets/png/vipLabelIcon.png" alt="">-->
<!--          </div>-->
         </ImgDecypt>
         <div class="titleBox">
          <div class="title ellipsis">
           <span>{{ item.title }}</span>
          </div>
          <div class="info flex-between">
           <div class="flex-aic">
            <svg-icon class="icon" iconClass="playCounts" />
            <span>{{ item.playCount }}</span>
           </div>
           <div class="flex-aic">
            <svg-icon class="icon" iconClass="photos" />
            <span>{{ item.imgCount }}</span>
           </div>
          </div>
         </div>
        </template>
       </div>
      </div>
     </PullRefresh>
     <Loading v-else/>
    </div>
   </div>
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh/index.vue";
import {mapGetters} from "vuex";
import {querySubModuleList} from "@/api/app";
import {AdType, getAdItem, getMarquee, jumpAdv, MarqueeType} from "@/utils/getConfig";
import ImgDecypt from "@/components/ImgDecypt/index.vue";
import Loading from "@/components/Loading/index.vue";
import selectTag from "@/views/publish/selectTag/index.vue";
import {queryMineCollectInfoList} from "@/api/community";
export default {
  name: "photoList",
  props: {
    modulesList: {
      type: Array,
    },
  },
  components:{
   Loading,
   PullRefresh,
   ImgDecypt,
  },
  data(){
    return {
      tabActive: 1,
      pageNumber: 1,
      pageSize: 10,
      isRefreshDisable: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      videoList: [],
      activeData: {},
      ratio: window.outerWidth / 375,
    }
  },
  computed: {
   computedRatio() {
    let height = 12;
    return (item) => {
     return { gridRowEnd: `span ${Math.floor(this.computedCoverHeight(item) / 5 / this.ratio + height)}` };
    };
   },
   ...mapGetters({
    userInfo: 'userInfo',
    photoTabIndex: 'photoTabIndex'
   }),
  },
  activated() {
    this.tabActive = this.photoTabIndex;
  },
  mounted(){
    this.querySubModuleList('refresh');
  },
  methods:{
    openDetail(item) {
     this.$store.dispatch('reader/savePhotoData', item);
     this.$router.push({
      path: "/photoDetails",
      query: {
       id: item.id,
      }
     });
     // let _this = this;
     // if (!this.userInfo.isVip && item.originCoins === 0) {
     //  _this.$bus.$emit("vipPopup", {
     //   state: 1,
     //   videoType: _this.kind,
     //   id: item.id,
     //   closeBtn: () => {
     //    _this.$bus.$emit("closeVipPopup");
     //   },
     //   buySuccess: () => {
     //    this.atlasData.hasPaid = true;
     //    this.$bus.$emit("closeVipPopup");
     //   },
     //  });
     // } else {
     //  this.$store.dispatch('reader/savePhotoData', item);
     //  this.$router.push({
     //   path: "/photoDetails",
     //   query: {
     //    id: item.id,
     //   }
     //  });
     // }
    },
    onChange(index) {
     this.$store.commit("app/SET_PHOTOINDEX", index);
     this.videoList = [];
    //  console.log(index);
     // this.activeData = this.modulesList[index];
     // if (index === 0) {
     //  this.querySubModuleList();
     // } else {
     // }
     this.pageNumber = 1;
     this.finished = false;
     this.isNoData = false;
     this.querySubModuleList('refresh');
    },
    select(item, index) {
    //  console.log(item);
     this.activeData = item;
     this.querySubModuleList();
    },
    goAdv(item) {
      jumpAdv(item);
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.querySubModuleList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.querySubModuleList('refresh');
    },
    async querySubModuleList(type) {
      let req;
      let res;
      let list;
      if (this.tabActive === 0) {
       req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: 'img',
        uid: this.$store.getters.userInfo.uid
       }
       res = await this.$Api(queryMineCollectInfoList, req);
       this.loading = false;
       this.refreshing = false;
       this.isLoading = false;
       list = res.data.list || [];
      } else {
       req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        subModuleId: this.modulesList[this.tabActive].id,
       };
       res = await this.$Api(querySubModuleList, req);
       this.loading = false;
       this.refreshing = false;
       this.isLoading = false;
       list = res.data.allVideoInfo || [];
      }
      console.log(list, 'daaaaaaaaaaa')
      if(list && res.code == 200) {
       if (type === "refresh") {
        this.videoList = list;
       } else {
        this.videoList = this.videoList.concat(list);
       }
       if (this.pageNumber === 1 && this.videoList.length === 0) {
        this.isNoData = true;
       }
       if (!res.data.hasNext || this.videoList.length < req.pageSize) {
        this.finished = true;
       }
      } else {
       this.error = true;
      }
    },
   computedCoverHeight(item) {
    if (item.ratio) {
     return 92 / item.ratio;
    } else {
     return 66;
    }
   },
  }
}
</script>
<style scoped lang="scss">
  .photoList{
    //height: calc(100vh - 40px);
    //overflow-y: auto;
    //overflow-x: hidden;
    //-webkit-overflow-scrolling: touch;
   .photoContent {
    padding: 11px;
    display: flex;
    justify-content: space-between;
    .leftBox {
     overflow-y: auto;
     width: 98px;
     height: 100%;
     border-radius: 8px;
     background: #F0F0F3;
     box-shadow: 5px 5px 4px 0px #AEAEC033 inset;
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
     padding: 0 6px;
     .item {
      width: 90px;
      height: 22px;
      line-height: 22px;
      font-weight: 900;
      font-size: 12px;
      color: #555555;
      text-align: center;
     }
     /deep/ .van-sidebar {
      width: 90px;
      .van-sidebar-item {
       width: 100%;
       height: 22px;
       //padding: 1.5px 15px;
       text-align: center;
       line-height: 22px;
       font-weight: 900;
       font-size: 12px;
       color: #555555;
       background: transparent;
       display: flex;
       align-items: center;
       justify-content: center;
       .van-sidebar-item__text {
        width: 83px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
       }
       .van-badge__wrapper {
        line-height: 22px;
        font-size: 12px;
       }
      }
      .van-sidebar-item--select {
        padding: 12px 0;
        width: 100%;
        height: 22px;
        line-height: 22px;
        text-align: center;
        border: 3px solid transparent;
        border-radius: 8px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #F0F0F3, #F0F0F3), linear-gradient(160deg, #0000000D, #D8D8D80D);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .van-sidebar-item--select::before {
       display: none;
      }
     }
    }
    .rightBox {
     width: calc(100vw - 84px);
     height: calc(100vh - 44px);
     background: #f5f5f5;
     //padding: 10px 16px 100px 12px;
     margin-left: 11px;
     box-sizing: border-box;
     .waterfall {
      //padding: 0 10px;
      display: grid;
      grid-auto-rows: 5px;
      grid-template-columns: repeat(auto-fill, 119px);
      align-items: start;
      justify-content: space-between;

      .photoBox {
       width: 119px;
       display: flex;
       flex-wrap: wrap;
       background: #FFFFFF;
       border-radius: 8px;

       .cover {
        width: 119px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        .label {
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         .icon{
          width: 32px;
          height: 20px;
         }
        }
       }

       .titleBox {
        width: 119px;
        .title {
         padding-top: 4px;
         width: 119px;
         color: #000000;
         font-size: 12px;
         font-weight: 600;
         line-height: 20px;
         word-wrap: break-word;
         span {
          padding-left: 5px;
         }
        }
        .info {
         align-items: center;
         padding: 0 5px 7px;
         .icon {
          width: 12.8px;
          height: 12.8px;
         }
         span {
          font-size: 10px;
          color: #777777;
          margin-left: 2px;
         }
        }
       }

      }
     }
    }
   }
  }
</style>
