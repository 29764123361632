<template>
  <div class="videoModule">
    <van-tabs
      class="navTabs oepnX"
      line-width="0"
      line-height="0"
      v-model="activeIndex"
      background="rgba(0,0,0,0)"
      v-if="modulesList && modulesList.length"
      :lazy-render="false"
      @change="change"
      swipeable
    >
      <van-tab v-for="(item, index) in modulesList" :key="index">
        <template #title>
          <span
            class="navTabTitle"
            :class="{ navTabTitleActive: activeIndex == index }"
          >
            {{ item.moduleName }}
          </span>
        </template>
        <HomeItem
          :item="item"
          :index="index"
          :scrollId="`${prefixKey}${index}`"
        />
      </van-tab>
    </van-tabs>
    <div class="navRight" @click="$router.push('/videoVaultPage')">
      <img src="@/assets/png/menuIcon.png" alt="" />
    </div>

    <div class="playletIconBox" @click="$router.push('/playletModule')">
      <img class="icon" src="@/assets/png/playletIcon.png" alt="" />
    </div>
  </div>
</template>
<script>
import { AdType, getAdItem } from "@/utils/getConfig";
import { mapGetters } from "vuex";
import { querySubModuleList } from "@/api/app";
import store from "@/store";
import TabPageScroll from "@/minxs/resetTabPageScrollTop";
import HomeItem from "./HomeItem/index.vue";

export default {
  name: "VideoModule",
  components: {
    HomeItem,
  },
  computed: {
    ...mapGetters({
      videoTabIndex: "videoTabIndex",
    }),
  },
  watch: {
    videoTabIndex(to, from) {
      this.activeIndex = to;
    },
  },
  mixins: [TabPageScroll],
  data() {
    return {
      bannerType: AdType.VIDEOHOMEADV,
      activeIndex: 0,
      modulesList: [],
      bannerList: [],
      jingangArea: [],
      videoList: [],
      advType: AdType.COLUMN_ADV,
      prefixKey: "videoModule_page_",
    };
  },
  activated() {
    this.$store.commit(
      "app/SET_ACTIVE_INDEX",
      `${this.prefixKey}${this.activeIndex}`
    );
    this.activeIndex = this.videoTabIndex;
  },
  mounted() {
    this.modulesList = this.$store.getters.modulesInfo?.homePage || [];
    // this.bannerList = getAdItem(AdType.COMMUNITY_RECOMMEND);
    // let appConfig = this.$store.getters.appConfig;
    // this.jingangArea = appConfig.jingangArea.list || [];
    setTimeout(() => {
      this.change(this.activeIndex);
    }, 1000);
  },
  methods: {
    change(index) {
      this.$store.commit("app/SET_VIDEOINDEX", index);
      this.$store.commit(
        "app/SET_ACTIVE_INDEX",
        `${this.prefixKey}${this.activeIndex}`
      );
      this.onChange(`${this.prefixKey}${this.activeIndex}`);
      // store.commit('app/SET_SCROLL_INFO', {
      //   key: this.$route.path,
      //   index: index,
      // });
    },
  },
};
</script>

<style scoped lang="scss">
.videoModule {
  height: 100%;
  background: url("../../assets/png/videoModuleHeaderBg.png") no-repeat;
  background-size: 100% 315px;
  background-color: #f9f9f9;

  .navTabTitle {
    font-size: 14px;
    color: #f5f5f5;
    font-weight: 600;
  }

  .navTabTitleActive {
    color: #ffe7bd;
    font-size: 18px;
    font-weight: 600;
  }

  /deep/ .van-tabs__wrap {
    // background-image: url("~@/assets/png/videoModuleBg.png");
    background-repeat: no-repeat;
    // backdrop-filter: blur(50px);
    // background-size: 100%;
    padding-top: 31px;
    padding-right: 35px;
    padding-left: 10px;
  }

  /deep/ .van-tab {
    padding: 0;
    margin-right: 22px;
  }

  /deep/ .van-tabs__line {
    background: transparent;
    background-image: url("~@/assets/png/tabLine2.png") !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 11px !important;
    height: 3.386px !important;
    bottom: 20px;
  }

  .navRight {
    position: absolute;
    right: 10px;
    top: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 20px;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .playletIconBox {
    position: absolute;
    right: 10px;
    bottom: 98px;

    .icon {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
